import "./style.css";

import { Feature, Map, View } from "ol";
import { Point } from "ol/geom";
import { Vector as LayerVector } from "ol/layer";
import { BingMaps, Vector as SourceVector } from "ol/source";
import TileLayer from "ol/layer/Tile";
import { Icon, Fill, Stroke, Style, Text } from "ol/style";
import { useGeographic } from "ol/proj";

useGeographic();

const key = promptForKey();

function promptForKey() {
  let key = window.localStorage.getItem("key");
  if (!key) {
    key = window.prompt("What is the key?");

    if (key.length < 50 || key.length > 100) {
      return promptForKey();
    }

    window.localStorage.setItem("key", key);
  }

  return key;
}

const locations = [
  ["Битола - Градски Парк", 41.019202, 21.340706, new Date(2016, 5, 4)],
  ["Скопје - кеј на Вардар", 42.011091, 21.41992, null],
  ["Молика", 41.044654, 21.214279, new Date(2016, 5, 30)],
  ["Охрид - Канео", 41.111929, 20.78891, null],
  ["Битола - кружен тек со филџан", 41.032555, 21.315833, new Date(2016, 6, 1)],
  ["Скопје - Парк Жена Борец", 41.993916, 21.433614, null],
  ["Скопје - Аеродром", 41.990249, 21.452709, null],
  ["Давид дома", 42.016258, 21.455645, new Date(2016, 7, 10)],
  [
    "Охрид - Билјанини Извори плажа",
    41.103366,
    20.806965,
    new Date(2016, 7, 14),
  ],
  ["Скопје - Пиволенд 2016", 42.001387, 21.433921, new Date(2016, 8, 3)],
  ["Пробиштип - Аква Парк", 42.005306, 22.176044, new Date(2016, 8, 4)],
  ["Прилеп - Парк на Патките", 41.343192, 21.547539, new Date(2016, 8, 10)],
  ["Скопје - Лептокарија", 42.004282, 21.398736, new Date(2016, 8, 22)],
  ["Скопје - Виноскоп", 41.996655, 21.435954, new Date(2016, 8, 30)],
  ["Скопје низ цел град", 42.009215, 21.413087, null],
  ["Банско", 41.827067, 23.486805, new Date(2016, 11, 2)],
  ["БИС - Нова Година", 42.006871, 21.402147, new Date(2016, 11, 31)],
  ["Маврово", 41.636263, 20.721443, new Date(2017, 0, 15)],
  ["Дихово - Villa Vista", 41.034612, 21.262578, new Date(2017, 11, 29)],
  ["Пониква", 42.03272, 22.354887, new Date(2017, 1, 12)],
  ["Капан Ан", 42.000127, 21.436807, new Date(2017, 1, 18)],
  [
    "Велес - Мостот на Сан Франциско",
    41.714712,
    21.785262,
    new Date(2017, 2, 4),
  ],
  ["Драгов Дол", 41.6014, 21.218275, new Date(2017, 8, 30)],
  ["Етно Село - Куманово", 42.157157, 21.862387, new Date(2017, 3, 16)],
  [
    "4bowling - Capitol Mall Скопје",
    41.98622,
    21.466512,
    new Date(2017, 5, 17),
  ],
  ["Дојран", 41.186946, 22.7228, new Date(2017, 6, 13)],
  ["Охрид - Golden Beach", 41.090066, 20.797633, new Date(2017, 7, 2)],
  ["Скопје - Пиволенд", 42.001578, 21.434285, new Date(2017, 7, 11)],
  ["Скијатос", 39.1483, 23.401245, new Date(2017, 7, 30)],
  ["Свадба на Мони - Континентал", 41.998832, 21.453494, new Date(2017, 9, 14)],
  ["Вевчани", 41.239432, 20.588232, new Date(2017, 9, 28)],
  [
    "BO Bowling - City Mall Скопје",
    42.003886,
    21.392068,
    new Date(2018, 1, 11),
  ],
  ["Маврово - Хотел Алпина", 41.650772, 20.734948, new Date(2017, 10, 25)],
  ["Давид дома - Аеродром", 41.982089, 21.456956, null],
  ["Попова Шапка", 42.006692, 20.877251, new Date(2018, 5, 8)],
  ["Хавана Неготино", 41.484146, 22.080268, new Date(2018, 6, 28)],
  ["Лефкада", 38.830332, 20.696328, new Date(2018, 7, 27)],
  ["Калабалак", 41.990126, 21.440339, new Date(2018, 9, 14)],
  ["Одисеја", 41.984397, 21.486864, new Date(2018, 8, 30)],
  ["Exit 2019 Camp", 45.235823, 19.842831, new Date(2019, 6, 3)],
  ["D-Festival 2019 Camp", 41.188336, 22.720888, new Date(2019, 6, 11)],
  ["Пефкохори", 39.99245, 23.60724, new Date(2019, 8, 7)],
  ["Беровско езеро", 41.673418, 22.907092, new Date(2019, 8, 28)],
  ["Банско - All Seasons Resort", 41.833458, 23.476625, new Date(2020, 1, 6)],
  ["Езеро Младост кампување", 41.771219, 21.758634, new Date(2020, 7, 1)],
  ["Охрид - Хотел Дипломат", 41.10728, 20.80938, new Date(2020, 8, 5)],
  ["Маврово", 41.632674, 20.716543, new Date(2021, 1, 19)],
  ["Лебедово Езеро - Струмица", 41.388442, 22.897389, new Date(2021, 7, 14)],
  ["Охрид - Penelopa Apartments", 41.109765, 20.805146, new Date(2021, 7, 20)],
  ["Село Вишни - Струга", 41.109765, 20.805146, new Date(2021, 7, 28)], // TODO: location
  [
    "Будва, Црна Гора - Hotel Pierina",
    42.286526,
    18.833117,
    new Date(2021, 8, 4),
  ],
  ["Солун/Agia Triada", 40.50063, 22.876395, new Date(2021, 8, 18)],
  ["Маврово - Rey Hotel", 41.676708, 20.735436, new Date(2021, 11, 28)],
  ["Копаоник - Vidik Residence", 43.276293, 20.757302, new Date(2022, 1, 23)],
  [
    "Осло - Forenom Serviced Apartments",
    59.919632,
    10.73282,
    new Date(2022, 4, 24),
  ],
  ["Tomorrowland", 51.095076, 4.397993, new Date(2022, 6, 21)],
  ["Tomorrowland - Брисел", 50.84423, 4.347399, new Date(2022, 6, 20)],
  ["Tomorrowland - Амстердам", 52.375265, 4.898196, new Date(2022, 6, 25)],
  ["Tomorrowland - Ајндховен", 51.440395, 5.479738, new Date(2022, 6, 26)],
  ["Втор крак - Сарти, Грција", 40.085685, 23.977066, new Date(2022, 7, 20)],
  ["Село Вишни - Струга", 40.085685, 23.977066, new Date(2022, 8, 9)], // TODO: location
  ["Прага живеалиште", 50.088774, 14.430876, new Date(2022, 11, 1)],
  ["Банско - Hotel Orphey", 41.837525, 23.493945, new Date(2023, 1, 15)],
  ["Виена", 48.211277, 16.372558, new Date(2023, 3, 17)],
  ["Рим - Hotel Taormina", 41.893721, 12.507666, new Date(2023, 7, 28)],
  ["Каљари - Cagliari Novecento", 39.216009, 9.110996, new Date(2023, 7, 31)],
  [
    "Париз - Ibis Budget Paris Porte d'Italie Est",
    48.815807,
    2.363729,
    new Date(2024, 3, 28),
  ],
];

function formatLabel(location, date) {
  if (!date) {
    return location;
  }

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDate = new Intl.DateTimeFormat("mk-MK", options).format(date);

  return location + "\n" + formattedDate;
}

const features = locations.map((location) => {
  const iconFeature = new Feature({
    name: location[0],
    geometry: new Point([location[2], location[1]]),
  });

  const style = new Style({
    image: new Icon({
      src: "/images/heart.webp",
      scale: 0.9,
    }),
  });

  const text = new Text({
    font: "12px 'Open Sans', sans-serif",
    fill: new Fill({ color: "#343434" }),
    stroke: new Stroke({
      color: "#fff",
      width: 3,
    }),
    backgroundFill: new Fill({ color: "rgba(255,255,255,.87)" }),
    backgroundStroke: new Stroke({
      color: "#888",
      width: 1,
    }),
    padding: [3, 6, 1, 7],
    offsetY: 39,
    text: formatLabel(location[0], location[3]),
  });

  style.setText(text);

  iconFeature.setStyle(style);

  return iconFeature;
});

const layer = new LayerVector({
  source: new SourceVector({
    features: features,
  }),
});

const map = new Map({
  target: "map",
  layers: [
    new TileLayer({
      source: new BingMaps({
        key: key,
        imagerySet: "AerialWithLabelsOnDemand",
      }),
    }),
    layer,
  ],
  view: new View({
    center: [14.875685, 48.120833],
    zoom: window.innerWidth > 768 ? 5 : 4,
  }),
});
